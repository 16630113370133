<template>
  <div class="md-manage-zheng-shu">
    <div class="pic-box">


      <el-tabs v-model="activeName">
        <el-tab-pane label="我的证书墙" name="first">
          <div style="display: flex;" class="imgs">
            <div v-for="(item, index) in list" :key="index" class="img-item">
              <img :src="item.level < userlevel? `/video-dev${item.avatar}`:`/video-dev${item.avatarGray}`" alt="" style="width: 82px;height: 90px;">
              <p>{{ item.name }}</p>
            </div>


          </div>

        </el-tab-pane>

      </el-tabs>
    </div>
  </div>
</template>

<script>

import { getCount, getZhengshu, getLevel } from '@/axios/user'

export default {
  name: 'MdManageZhengShuComponent',
  data() {
    return {
      isMaskShow: false,
      dialogVisible: false,
      count: 0,
      list: [],
      activeName: 'first',
      userlevel: 0,
    }
  },
  created() {
    this.getCount()
    this.getZhengshu()
    this.getLevel()
  },
  methods: {
    check() {
      this.dialogVisible = true
    },
    async getCount() {
      const res = await getCount()
      if (res.code == 200) {
        this.count = res.data
      }
    },
    //获取用户当前等级
    async getLevel() {
      const res = await getLevel()
      if (res.code == 200) {
        this.userlevel = res.data.level
      }
    },
    async getZhengshu() {
      const res = await getZhengshu()
      if (res.code == 200) {
        this.list = res.rows
      }
      console.log(res);
    }
  }
}
</script>
<style lang="less" scoped>
.md-manage-zheng-shu {
  width: 100%;
  background: #fff;
  padding: 20px;

  .pic-box {
    height: 300px;
    padding: 0 20px;
    margin-top: 11px;

    p {
      font-size: 14px;
      color: #333;
      // margin-bottom: 20px;
    }

    .img-box {
      width: 200px;
      position: absolute;

      img {
        width: 100%;
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 20px;
          padding: 5px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .provide-img {
    width: 450px;
    margin: 0 auto;
  }
}

::v-deep {
  .is-active {
    color: #0FB09B !important;
  }

  .el-tabs__active-bar {
    background-color: #0FB09B;
  }

  .el-tabs__item {
    font-size: 16px;
    color: #333333;
  }

  .el-tabs__item:hover {
    color: #0FB09B !important;
  }
}

.imgs{
  >:nth-child(1){
    margin: 0px;
  }
}
.img-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 40px;
 
    p{
      text-align: center;
      margin: 12px 0 0 0 !important;
      font-size: 14px;
    }
}

/deep/ .el-dialog__body {
  padding: 20px;
  display: flex;
  justify-content: center;
}
</style>